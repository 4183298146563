import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins'
import VideoBackground from 'vue-responsive-video-background-player'
import VueYoutube from 'vue-youtube'
import app from '@/firebase/firebase'
import i18n from './i18n'

Vue.use(VueYoutube)
Vue.component('VideoBackground', VideoBackground)
Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
