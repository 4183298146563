<template>
  <div
    :class="classes"
    class="pt-2"
  >
    <v-row
      v-if="!flags[1]"
    >
      <v-col
        md="12"
      >
        <base-img
          :src="flags[0]"
          height="40"
          contain
          class="mx-auto"
          @mouseover="fnMakeItBigger($event)"
          @mouseleave="fnMakeItSmaller($event)"
        />
      </v-col>
    </v-row>
    <v-row
      v-if="flags[1]"
      class="justify-center"
    >
      <v-col
        md="6"
      >
        <base-img
          v-if="flags[0]"
          :src="flags[0]"
          height="40"
          class="mx-auto mr-0"
          @mouseover="fnMakeItBigger($event)"
          @mouseleave="fnMakeItSmaller($event)"
        />
      </v-col>
      <v-col
        md="6"
      >
        <base-img
          v-if="flags[1]"
          :src="flags[1]"
          height="50"
          class="mx-auto ml-0"
          @mouseover="fnMakeItBigger($event)"
          @mouseleave="fnMakeItSmaller($event)"
        />
      </v-col>
    </v-row>
    <base-avatar
      v-if="icon"
      :color="color"
      :dark="dark"
      :icon="icon"
      :outlined="outlined"
      :size="size"
      class="mb-3 align-self-start"
    />

    <div :class="horizontal && title && 'ml-6'">
      <base-title
        :title="location"
        class="text-uppercase overflow-y-auto"
        space="3"
        mobile-size="text-subtitle-2"
      />
    </div>
    <base-divider
      v-if="chosen"
      color="third"
    />
  </div>
</template>

<script>
  // Mixins
  import Heading from '@/mixins/heading'

  export default {
    name: 'BaseAvatarCardPortfolio',

    mixins: [Heading],

    props: {
      align: {
        type: String,
        default: 'left',
      },
      color: String,
      dark: Boolean,
      horizontal: Boolean,
      icon: String,
      outlined: {
        type: Boolean,
        default: true,
      },
      space: {
        type: [Number, String],
        default: 8,
      },
      size: {
        type: [Number, String],
        default: 72,
      },
      text: String,
      title: String,
      flags: [],
      location: String,
      chosen: Boolean,
    },

    computed: {
      classes () {
        const classes = [
          `mb-${this.space}`,
        ]

        if (this.horizontal) {
          classes.push('d-flex')

          if (!this.$slots.default && !this.text) {
            classes.push('align-center')
          }
        }

        return classes
      },
    },
    methods: {
      fnMakeItBigger (event) {
        event.currentTarget.style.height = '50px'
      },
      fnMakeItSmaller (event) {
        event.currentTarget.style.height = '40px'
      },
    },
  }
</script>
