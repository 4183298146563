<template>
  <div>
    <base-img
      :height="prominent ? 400 : 250"
      :src="src"
      flat
      tile
    />

    <div
      class="pa-4"
      min-height="100"
    >
      <base-title
        :title="title"
        class="text-uppercase"
      />

      <base-body
        :text="!html ? truncatedText : undefined"
        :html="html"
        space="0"
      />
    </div>

    <v-btn
      v-if="readMore"
      class="font-weight-bold primary"
      text
      @click="fnGoToContact"
    >
      Request Sample
    </v-btn>

    <v-divider
      v-if="divider"
      class="mt-6"
    />
  </div>
</template>

<script>
  export default {
    name: 'NewsCardProducts',

    props: {
      category: String,
      comments: [Number, String],
      date: String,
      divider: Boolean,
      html: String,
      icon: String,
      prominent: Boolean,
      readMore: Boolean,
      src: String,
      text: String,
      title: String,
      truncate: [Number, String],
    },

    computed: {
      truncatedText () {
        if (!this.text) return ''

        const truncate = Number(this.truncate)

        return this.text.length > truncate
          ? this.text.slice(0, truncate) + ' [...]'
          : this.text
      },
    },
    methods: {
      fnGoToContact () {
        this.$router.push('/contact')
      },
    },
  }
</script>
