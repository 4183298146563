<template>
  <div>
    <base-info-card
      :title="title"
      :subtitle="subtitle"
      space="4"
    />
    <br>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-select
        v-model="selected"
        :items="category"
        label="Category"
        dense
        menu-props="auto"
      />
      <v-text-field
        v-model="name"
        :counter="20"
        :rules="nameRules"
        required
        flat
        label="Name*"
      />
      <v-text-field
        v-model="email"
        :rules="emailRules"
        required
        flat
        label="Email*"
      />
      <v-textarea
        v-model="message"
        :rules="messageRules"
        required
        flat
        label="Message*"
        :counter="1000"
        height="100"
      />
      <v-btn
        v-if="!loading"
        :disabled="!valid"
        color="primary"
        x-large
        @click="sendEmail"
      >
        Submit
      </v-btn>
      <v-progress-circular
        v-if="loading"
        :size="50"
        color="primary"
        indeterminate
      />
    </v-form>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'BaseContactForm',

    // Injected from the Vuetify Themeable mixin
    inject: ['theme'],

    props: {
      subtitle: String,
      title: {
        type: String,
        default: 'MAIL US YOUR MESSAGE',
      },
    },
    data () {
      return {
        loading: false,
        valid: true,
        name: '',
        nameRules: [
          (v) => !!v || 'Name is required',
          (v) => (v && v.length <= 20) || 'Name must be less than 20 characters',
        ],
        email: '',
        emailRules: [
          (v) => !!v || 'E-mail is required',
          (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        message: '',
        messageRules: [
          (v) => !!v || 'Message is required',
        ],
        category: [
          'General Inquiry',
          'Project Inquiry',
          'Feedback',
        ],
        selected: 'General Inquiry',
      }
    },
    methods: {
      sendEmail () {
        this.valid = false
        let param = {}

        param = {
          name: this.name,
          email: this.email,
          message: this.message,
          category: this.selected,
        }

        axios.post('https://sub.metaverselogic.io/email/howellcareindustries', param)
          .then((data) => {
            console.log(data)
            if (data.status === 200) {
              alert('Thank you. We will get back to you soon.')
              this.$router.go()
            } else {
              alert('Something is wrong. Please try it again.')
              this.valid = true
            }
          }).catch((error) => {
            console.log(error)
            this.valid = true
          })
      },
    },
  }
</script>
